import React from 'react'
import CompanyLogo from '../../img/CompanyLogoFontWhite.png'
// import { CiWarning } from "react-icons/ci";

const UnderMaintenance = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#1F2937] text-white">
        <div className="text-center mx-auto p-8">
            <div className='flex items-center justify-center text-7xl my-2'>
            {/* <CiWarning/> */}
            <img className='w-[15rem] px-1' src={CompanyLogo} alt="" />
            </div>
            <h1 className="text-5xl font-light my-10 inline-block">We will be back soon!</h1>
            <div className='max-w-lg'>
              <p className="text-lg mb-4">Hey there! Sorry for the inconvenience. We are currently performing some maintenance. For any inquiry, Feel free through reach us out on &nbsp; 
                <a href="https://www.instagram.com/gammatechid/" className="font-bold text-white underline">Instagram</a>
                &nbsp; or &nbsp;
                 <a href="https://wa.me/6285156073775" className='font-bold text-white underline'>Whatsapp</a>
                </p>
            </div>
        </div>
    </div>
  )
}

export default UnderMaintenance