import React, { useState, useEffect } from 'react';
import {AiOutlineArrowUp} from 'react-icons/ai'

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-12 right-9 bg-blue-500 text-white px-4 py-2 mb-[4rem] rounded-md z-[9] ${
        isVisible ? 'visible' : 'invisible'
      }`}
      //99999
    >
      <AiOutlineArrowUp size={25} /> 
    </button>
  );
}

export default ScrollToTopButton;
