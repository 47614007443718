import React, { useEffect } from 'react'
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// component
import './Component/ScrollBar.css'
import Navbar from './Component/Navbar/navbar';

import ScrollButton from './Component/ScrollButton';
import LandingPage from './Component/Hero/LandingPage';
import Content from './Component/ProductsServices/Content';
import ContentTwo from './Component/ProductsServices/ContentTwo';
import Solution from './Component/Solutions/Solution';
import WhyUs from './Component/Information/WhyUs';
import ContactNews from './Component/Information/ContactNews';
import FooterCheck from './Component/Footer/FooterCheck';



// necessities
import AOS from 'aos';
import 'aos/dist/aos.css';
// import ReactGA from 'react-ga';
// import { BubbleChat } from 'flowise-embed-react/dist/web.js';

import './index.css'
import ContentThree from './Component/ProductsServices/ContentThree';
import ContentFour from './Component/ProductsServices/ContentFour';
import ContentFive from './Component/ProductsServices/ContentFive';
// import FlowiseEmbed from './Component/FlowiseEmbed';
import BotPress from './Component/BotPress';
import UnderMaintenance from './Component/Temp/UnderMaintenance';

// const TrackingID = 'G-BP7KPCG335';
// ReactGA.initialize(TrackingID);

function App() {
  // useEffect (() =>
  // {

  //   // ReactGA.pageview(window.location.pathname);    
    
  //   AOS.init({
  //     easing: 'ease-in-out',
  //     once: false,
  //     mirror: false,
  //   });
  //   AOS.refresh();
  // }, []);
  
  return (
    <div style={{ overflowX: 'hidden' }}>

      {/* <FlowiseEmbed /> */}
      <UnderMaintenance/>
{/* 

      <BotPress/>

      <ScrollButton />
      
      <Navbar />

      <LandingPage />


      <div data-aos='fade-left' data-aos-duration='1000'  data-aos-delay='500'>
        <Content />
      </div>


      <div data-aos='fade-right' data-aos-duration='1000'  data-aos-delay='400'>
        <ContentTwo />
      </div>

      <div data-aos='fade-left' data-aos-duration='1000'  data-aos-delay='500'>
        <ContentThree/>
      </div>

      <div data-aos='fade-right' data-aos-duration='1000'  data-aos-delay='400'>
        <ContentFour />
      </div>

      <div data-aos='fade-left' data-aos-duration='1000'  data-aos-delay='500'>
        <ContentFive />
      </div>

      <div data-aos='fade-down' data-aos-duration='1000'  data-aos-delay='400'>
        <Solution />
      </div>

      <WhyUs />
      
      <div data-aos='fade-down' data-aos-duration='1000'  data-aos-delay='400'>
        <ContactNews />
      </div>

      <FooterCheck /> */}
    </div>

);
}

export default App;


// // Google Analytics tracking code
// window.dataLayer = window.dataLayer || [];
// function gtag() {
//   window.dataLayer.push(arguments);
// }
// gtag('js', new Date());
// gtag('config', 'G-SC1G812FDE'); // Replace with your tracking ID


// async function query(data) {
//   const response = await fetch(
//       "http://localhost:3000/api/v1/prediction/5673ddf4-06d8-49a0-946a-b09ceb06240a",
//       {
//           method: "POST",
//           headers: {
//               "Content-Type": "application/json"
//           },
//           body: JSON.stringify(data)
//       }
//   );
//   const result = await response.json();
//   return result;
// }

// query({"question": "Hey, how are you?"}).then((response) => {
//   console.log(response);
// });

// // Pop-Up React
// import { BubbleChat } from 'flowise-embed-react'

// const App = () => {
//   return (
//       <BubbleChat chatflowid="5673ddf4-06d8-49a0-946a-b09ceb06240a" apiHost="http://localhost:3000" />
//   );
// };